/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  padding: 20px;
  width: 400px;
  height: 80vh;
  background: white;
  color: black;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  overflow-y: scroll;
}


.modal input[type=text][id=name] {
  font-size: 22px;
  font-weight: bold;
}

.modal form label {
  display: block;
  margin-bottom: 5px;
}

.modal form input {
  background: transparent;
  border: none;
}

.modal form textarea {
  background: transparent;
  border: none;
  display: block;
  width: 100%;
}

.modal form input:hover,
textarea:hover {
  border: 1px solid #ececec;
}