
  .dropzone {
    flex: 1;
    height: 400px;
  }
  
  .dropzone.left {
    margin-right: 10px;
  }
  
  .grid-item {
    /* padding: 10px; */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  .grid-item-content {
    width: 50px;
    height: 80px;
    box-sizing: border-box;
    background: #08e;
    display: flex;
    justify-content: center;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8px;
    align-items: center;
    padding: 5px;
    border-radius: 3px;
    border: 3px solid white;
  }
  